.top-level-header {
  min-height: 81px;
  background-color: #252525;
  position: relative;
}

.logo-icon-style {
  height: 70px;
}

.header-list {
  text-decoration: none;
  list-style: none;
}
.header-list-item {
  text-decoration: none;
  margin-right: 15px;
  font-size: 18px;
}
.font-weight-light {
  font-weight: bold !important;
  color: white;
  font-family: InterRegular;
}
.font-weight-light:hover {
  color: #f49d1a;
  cursor: pointer;
}
.active {
  color: #f49d1a !important;
}
.logout-btn {
  background-color: #f49d1a;
  border: 0px;
  padding: 5px 20px;
  border-radius: 5px;
  color: white;
  font-family: InterRegular;
  font-size: 15px;
}

.logout-btn:hover {
  opacity: 0.8;
}

.menu-bar {
  cursor: pointer;
  margin-left: 10px;
}
