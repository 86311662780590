.blog-card-container {
  padding: 20px;
  background-color: #faede2;
  border-radius: 5px;
}

.blog-card-img {
  height: 120px;
  width: 220px;
  object-fit: cover;
}

.blog-card-title {
  text-align: left;
  color: #104660;
  font-family: InterRegular;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
  margin-bottom: 0px;
}

.dot-pending {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #e48a37;
}

.dot-done {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: green;
}

.blog-card-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.blog-card-subtitle {
  text-align: left;
  color: #104660;
  opacity: 0.7;
}
.icon-style {
  width: 20px;
  height: 20px;
}
.secndry-btn {
  background-color: #104660;
  border: 0px;
  color: white;
  padding: 10px 20px;
  margin: 2px;
  border-radius: 5px;
  font-family: InterRegular;
  justify-content: center;
  display: flex;
  text-align: center;
  min-width: 150px;
  align-items: center;
  min-height: 40px;
}

@media screen and (max-width: 550px) {
  .secndry-btn {
    min-width: 70px;
    font-size: 15px;
  }
  .icon-style {
    width: 17px;
    height: 17px;
  }
}

.secndry-btn:hover {
  opacity: 0.8;
}
