.login-main-container {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-title {
  font-family: InterBold;
  color: #145777;
}

.login-btn {
  background-color: #145777;
  border: 0px;
  padding: 10px 60px;
  border-radius: 5px;
  color: white;
  font-family: InterRegular;
  min-width: 200px;
}

.login-btn:hover {
  opacity: 0.8;
}

.spinner {
  font-size: 13px !important;
}
.error {
  color: red;
  font-size: 11px;
  text-align: left;
  position: absolute;
  top: 60px;
  z-index: 1;
  /* margin: 10px 0; */
}
