.imgStyle {
  height: 72px;
  width: 72px;
}
.detail-prod-title {
  font-family: InterBold;
  color: #252525;
  font-size: 16px;
}

.x-label {
  font-family: InterMedium;
  color: #252525;
  font-size: 12px;
}

.detail-prod-user-title {
  font-family: InterMedium;
  color: #252525;
  font-size: 24px;
}

.detail-prod-name {
  font-family: InterRegular;
  color: #252525;
  font-size: 14px;
}

.detail-prod-name-bold {
  font-family: InterBold;
  color: #252525;
  font-size: 16px;
}

.mdModal {
  max-width: 700px !important;
}
