.times {
  cursor: pointer;
}
.offcanvas-backdrop.show {
  opacity: 1;
}
.offcanvas-backdrop.fade {
  opacity: 0;
  visibility: hidden;
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 65%);
  transition: opacity 0.15s linear;
}

.offcanvas.show {
  transform: none;
  visibility: visible;
  background-color: #252525;
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 250px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0px 30px;
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 30px 30px 0px 30px;
  overflow-y: auto;
}

.header-list ul li a {
  margin-right: 25px;
  color: white;
  font-weight: 700;
}
