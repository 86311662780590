.info-card-container {
  background-color: #faede2;
  padding: 20px 20px;
  border-radius: 5px;
  min-height: 180px;
}

.info-card-title {
  font-family: InterBold;
  color: #145777;
  text-align: left;
}

.info-card-sub {
  font-family: InterRegular;
  color: #145777;
  text-align: left;
}
